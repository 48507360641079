import { Button, DatePicker, Form, Input, InputNumber, Radio, Select, Divider, Space, Checkbox } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import styles from './index.module.css'
import ecLogo from '../../img/logoExpandedRow.png'
import aluLogo from '../../img/aluLogo.png'
import { useState } from 'react';
import { Required } from '../common/helper'
import Uploader from '../common/Uploader'
import diseaseList from './disease.json'
import countries from './countries.json'
const OnboardALU = () => {

    const [step, setStep] = useState(0)
    const [otp, setOtp] = useState('')
    const [kycDoc, setKycDoc] = useState([])
    const [mainForm] = Form.useForm()

    const [submitDisabled, setSubmitDisabled] = useState(true)

    const onOtpEnter = e => {
        console.log(String(e.target.value).match(/[0-9]/g))
        if (e.target.value.length < 8 && String(e.target.value).match(/[0-9]/g)) {
            setOtp(e.target.value)
        }
    }

    const [countryCode, setCountryCode] = useState()

    const getDiseaseOptions = (gender) => diseaseList.map(c => { return { label: c.condition, options: c.disease.filter(d => d.gender.includes(gender)).map(d => { return { label: d.name, key: d.name, value: d.name } }) } })
    const [maleConditions, setMaleConditions] = useState(getDiseaseOptions('Male'))
    const [femaleConditions, setFemaleConditions] = useState(getDiseaseOptions('Female'))
    const addItem = (e, gender, memberType) => {
        e.preventDefault();
        if (gender === 'Male') {
            if (maleConditions.filter(a => a.label === 'Other').length === 0) {
                setMaleConditions(maleConditions => [...maleConditions, {
                    label: 'Other',
                    options: [{ label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }]
                }])
            }
            else {
                setMaleConditions(maleConditions => [
                    ...maleConditions.slice(0, 9),
                    maleConditions[9] = { ...maleConditions[9], options: [...maleConditions[9].options, { label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }] }
                ])
            }
        }
        else {
            if (femaleConditions.filter(a => a.label === 'Other').length === 0) {
                setFemaleConditions(femaleConditions => [...femaleConditions, {
                    label: 'Other',
                    options: [{ label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }]
                }])
            }
            else {
                setFemaleConditions(femaleConditions => [
                    ...femaleConditions.slice(0, 9),
                    femaleConditions[9] = { ...femaleConditions[9], options: [...femaleConditions[9].options, { label: mainForm.getFieldValue('custom_disease'), value: mainForm.getFieldValue('custom_disease'), key: mainForm.getFieldValue('custom_disease') }] }
                ])
            }
        }
    };
    return (
        <div className={styles.cardShadow} style={{ marginTop: step < 2 ? '-50px' : null }}>
            <div style={{ display: 'flex', gap: '25px', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={ecLogo} className={styles.ecLogo} />
                <div className={styles.vDivider}></div>
                <img src={aluLogo} className={styles.aluLogo} />
            </div>
            <div style={{ marginTop: '15px', padding: '0px 5px', marginBottom: '5px' }}>
                {step !== 3 && <p style={{ fontSize: '22px', fontWeight: "600", marginBottom: '0px', marginTop: '0px' }}>Onboarding</p>}

                {step === 1 &&
                    <div>
                        <p style={{ fontSize: '16px', fontWeight: "400", marginTop: '5px' }}>Please enter OTP received on your email</p>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                            <Input onChange={onOtpEnter} placeholder='Enter OTP' value={otp} type='number' style={{ borderRadius: '13px', height: '36px' }} />
                            <Button
                                style={{ width: '100px', borderRadius: '13px', height: '36px' }}
                                type='primary'
                                onClick={() => setStep(2)}
                            >Proceed</Button>
                        </div>
                    </div>
                }

                {step === 0 &&
                    <div>
                        <p style={{ fontSize: '16px', fontWeight: "400", marginTop: '5px' }}>Please enter your email address to proceed</p>
                        <Form onFinish={e => setStep(1)} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <Form.Item
                                name='email'
                                rules={[
                                    { type: 'email', message: 'Enter valid email address' },
                                    { required: true, message: 'Required' }
                                ]}
                            >
                                <Input placeholder='Enter email address' type='email' style={{ borderRadius: '13px', height: '36px' }} />
                            </Form.Item>
                            <Button style={{ width: '100px', borderRadius: '13px', height: '36px' }} type='primary' htmlType='submit'>Proceed</Button>
                        </Form>
                    </div>
                }

                {step === 2 &&
                    <div>
                        <p style={{ fontSize: '16px', fontWeight: "400", marginTop: '5px' }}>Hello Sumit, Please fill in your details to continue your onboarding process.</p>
                        <Form form={mainForm} onFinish={e => setStep(3)} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div>
                                <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>Gender {Required}</p>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'Required' }
                                    ]}
                                >
                                    <Radio.Group name="radiogroup" defaultValue={1}>
                                        <Radio value={'Male'}>Male</Radio>
                                        <Radio value={'Female'}>Female</Radio>
                                        <Radio value={'Prefer not to say'}>Prefer not to say</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>Date of Birth {Required}</p>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'Required' }
                                    ]}
                                >
                                    <DatePicker showToday={false} placeholder='Select Date of Birth' className={styles.fieldWidth} style={{ height: '36px', marginTop: '8px', borderRadius: '13px' }} />
                                </Form.Item>
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>NIDA or Passport Number {Required}</p>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'Required' }
                                    ]}
                                >
                                    <Input placeholder='Enter NIDA/Passport Number' className={styles.fieldWidth} style={{ marginTop: '8px', borderRadius: '13px', height: '36px', width: '385px' }} />
                                </Form.Item>
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>Personal Email </p>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'Required' },
                                        { type: 'email', message: 'Enter valid email address' },
                                    ]}
                                >
                                    <Input placeholder='Enter Personal Email' className={styles.fieldWidth} style={{ marginTop: '8px', borderRadius: '13px', height: '36px', width: '385px' }} />
                                </Form.Item>
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>Contact Number {Required}</p>
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '8px' }}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'Required' }
                                        ]}
                                    >
                                        <Select
                                            placeholder='Country'
                                            style={{ width: '85px', margin: '0px', height: '36px' }}
                                            options={countries}
                                            value={countryCode}
                                            onChange={e => setCountryCode(e)}
                                            labelInValue={true}
                                            popupMatchSelectWidth={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'Required' }
                                        ]}
                                    >
                                        <Input type='number' placeholder='Enter Contact Number' className={styles.input} />
                                    </Form.Item>

                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>KYC Document {Required}</p>
                                    <Form.Item
                                        style={{ marginTop: '10px' }}
                                        rules={[
                                            { required: true, message: 'Required' }
                                        ]}
                                    >
                                        <Uploader setFiles={setKycDoc} maxFiles={1} allowedExtensions='.PDF, .PNG, .JPG, .JPEG' />

                                    </Form.Item>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <p style={{ fontSize: '16px', fontWeight: '600', margin: '0px' }}>Pre-Existing Conditions</p>
                                    <Form.Item name='conditions' style={{ width: '100%', marginTop: '5px' }}>
                                        <Select
                                            placeholder='Select Pre-Existing Conditions'
                                            className={styles.diseaseSelect}
                                            mode='multiple'
                                            allowClear
                                            options={maleConditions}
                                            // options={member?.gender === 'Male' ? maleConditions : femaleConditions}
                                            dropdownRender={(menu) =>
                                            (<>

                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Form.Item name='custom_disease'>
                                                        <Input className={styles.customDisease} placeholder='If any other disease, mention here' />
                                                    </Form.Item>
                                                    <Button style={{ borderRadius: '13px' }} type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, 'Male')}>
                                                        Add
                                                    </Button>
                                                </Space>
                                            </>)

                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <Button
                                style={{ width: '100px', borderRadius: '13px', height: '36px', marginTop: '15px' }}
                                type='primary'
                                htmlType='submit'
                            >Proceed</Button>
                        </Form>
                    </div>
                }
                {step === 3 &&
                    <div>
                        <p className={styles.formTitle} style={{ marginTop: '0px', fontWeight: '600' }} >Self Declaration Form</p>

                        <p className={styles.formLabel} style={{ fontWeight: '500', fontSize: '16px' }}>Declaration Acknowledgement</p>
                        <p className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>This membership declaration is part of the contract with Eden Care Medical, Rwanda.</p>
                        <p className={styles.formLabel} style={{ fontWeight: '500', fontSize: '16px', marginTop: '5px' }}>e-Acknowledgement</p>
                        <ol style={{ marginTop: '0px' }}>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I declare that all the persons named in the application form are members of my immediate family for whose membership I am responsible.
                            </li>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I hereby apply to join the above mentioned health insurance plan.
                            </li>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I understand that any mis-statement or the non-disclosure of any material information in this form will jeopardise my membership.
                            </li>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I warrant that the answers in this form are true, correct, and complete and I acknowledge that such answers are all material.
                            </li>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I hereby authorise the hospital, medical or dental
                                practitioners who have treated me or any of my dependants to
                                disclose to the company the records relating to such current or
                                previous hospitalisations/ medical treatment and to allow the
                                company to receive extracts from such records and undertake
                                to assist in obtaining such information.
                            </li>
                            <li className={styles.formValue} style={{ fontWeight: '300', fontSize: '14px' }}>
                                I have read, understood and agree with the cover options,
                                exclusions, terms and conditions as stipulated in the product brochure and benefit schedule.
                            </li>
                        </ol>
                        <div style={{ display: 'flex' }}>
                            <Form.Item labelAlign="left"
                                name="member_ack" valuePropName="checked" >
                                <Checkbox
                                    onChange={() => setSubmitDisabled(false)}
                                />
                            </Form.Item>
                            <p className={styles.formValue} style={{ marginTop: '5px', marginLeft: '10px', fontWeight: '500', fontSize: '14px' }}>I acknowledge this self-declaration medical history form and confirm that the information presented by me is accurate and correct to my knowledge.</p>
                        </div>
                        <Button disabled={submitDisabled} type='primary' style={{ borderRadius: '13px', height: '36px', marginTop: '25px' }}>Submit</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default OnboardALU;