import { notification } from "antd";

export const openNotification = (msg, title) => {
    notification.open({
        message: title,
        description: msg,
        style: {
            borderRadius: '15px',
        },
    });
};


export const Required = <span style={{ fontSize: '12px', fontWeight: '300', fontStyle: 'italic' }}>Required</span>