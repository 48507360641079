import OnboardALU from "./components/OnboardALU";

function App() {
  return (
    <div style={{ backgroundColor: '#fafafa', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
      <OnboardALU />
    </div>
  );
}

export default App;
