import styles from './index.module.css'
import { MinusCircleOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { openNotification } from '../helper';

const Uploader = ({ disabled, setFiles, maxFiles, allowedExtensions }) => {
    const { Dragger } = Upload;
    const [fileList, setFileList] = useState([]);

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    useEffect(() => {
        setFiles(fileList)
    }, [fileList])

    const removeFile = file => {
        setFileList(fileList.filter(a => a.uid == file.uid))
    }

    return (
        <Upload
            disabled={disabled || fileList.length === maxFiles}
            multiple={true}
            onChange={e =>
                e?.file?.size > 20971520 ? openNotification('Please select file less than 20MB', 'Error') :
                    setFileList(e.fileList, setFiles(e.fileList))
            }
            customRequest={dummyRequest}
            fileList={fileList}
            onRemove={e => console.log('remove')}
            accept={allowedExtensions}
            showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
        // listType='text'
        >
            <Button style={{ borderRadius: '12px' }} disabled={disabled || fileList.length === maxFiles} icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        // <div>
        //         <Dragger
        //             disabled={disabled || fileList.length === maxFiles}
        //             multiple={true}
        //             onChange={e => {
        //                 // console.log(e?.file?.size > 20480)
        //                 e?.file?.size > 20971520 ? openNotification('Please select file less than 20MB', 'Error') :
        //                     setFileList(e.fileList, setFiles(e.fileList))
        //             }
        //             }
        //             customRequest={dummyRequest}
        //             fileList={fileList}
        //             showUploadList={false}
        //             style={{ backgroundColor: 'white', maxHeight: '130px', }}
        //             accept={allowedExtensions}
        //         >


        //             <div style={{ height: '120px', minWidth: '130px', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //                 <p style={{ margin: '0px', fontSize: '16px', fontWeight: '300' }}>
        //                     Select<br /> or<br /> Drag Files here
        //                 </p>

        //             </div>
        //         </Dragger>
        // </div>
    );
};
export default Uploader;